import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const plaintedunvagabond = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Plainte d'un vagabond" />
    <h3 className='underline-title'>Plainte d'un vagabond</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Mbomba (*) apporte moi ma guitare<br />
        Mbomba (*) apporte moi mon xylophone<br />
        Femme-poisson dépêche-toi, le temps presse<br />
        Je dois chanter je vais me plaindre<br />
        Ô courtisante Mbomba<br />
        Nymphe nageuse aux cheveux frondaisons<br />
        Tends l'oreille transmets mes plaintes hâtives<br />
        Je viens de loin je viens de là-bas<br />
        Là-bas où la forêt luxuriante<br />
        Défie le soleil en furie ignée<br />
        D'où Drysale équatoriale m'a congédié<br />
        Je vadrouille dans un plateau sans issue<br />
        Le sol équatorial fuit sous mes pas<br />
        Je navigue à contre-courant battu par les flots<br />
        Comme épave sans valeur je dérive<br />
        Pour échouer aux champs folkloriques des bananiers<br />
        </p>
        <p>
        Femme-poisson sois hospitalière<br />
        Alarme sans délai les nymphes lacustres<br />
        Délègue le cormoran aéro-plongeur<br />
        Pour annoncer aux mânes bashi montagnards<br />
        Mon injuste et humiliante dérive<br />
        <p>
        </p>
        Mânes des montagnes nymphes du lac<br />
        Veuillez que je sois comme Paris:<br />
        <span className="black-bold">Fluctuat nec mergitur</span><br />
        Prenez en fils l'étranger qui vous implore<br />
        Retenez-le au port d'échouement vespéral<br />
        Rognez-lui les ailes pour ne franchir d'autres airs<br />
        Ceinturez-le de vos monts hermétiques<br />
        Barrages certains aux pénates courroucés<br />
        Pénates attardés de l'Équateur rétrograde<br />
        </p>
    </div>
    <hr/>
    <p className="font-italic">
        (*) Équivant de Néréide (habitant le fleuve chez la tribu Ngbandi)
      </p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default plaintedunvagabond
